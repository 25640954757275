* {
  box-sizing: border-box;
}

html, body {
  background: #FAFAFA;
  height: 100%;
  position: relative;
}

#root {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

nav {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

hr {
  border-width: 2px;
}

.jumbotron {
  background: linear-gradient(
    rgba(0, 0, 0, 0.3), 
    rgba(0, 0, 0, 0.2)
  ), url(/static/media/hero.e6f6166f.jpg);
  background-position: 50% 75%;
  background-size: cover;
  color: #FFF;
}

.card {
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 5px;
}

.rounded-img {
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.double-space {
  line-height: 2.1em;
  font-size: 1.1rem;
  font-weight: 400;
}

.section-header {
  font-weight: bold;
  padding: 20px 0;
  margin-bottom: 25px;
  border-bottom: 4px solid #E0E0E0;
}

.jumbotron h1, .jumbotron .lead {
  font-weight: bold;
}

.jumbotron hr {
  border: 1px solid rgba(255, 255, 255, 0.212);
  width: 50%;
}

.post-card {
  color: #212121;
}

.post-card:hover {
  color: #212121;
  text-decoration: none;
}

.postitem.card {
  transition: box-shadow 0.25s ease-out;
}

.postitem.card:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.social-card {
  color: #212121;
  transition: box-shadow 0.25s ease-out;
}

.social-card:hover {
  color: #212121;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.fa-linkedin {
  color: #007bb5;
}

.fa-facebook {
  color: #1877f2;
}

.fa-code {
  color: #03A9F4;
}

.fa-camera {
  color: #FF9800;
}

.fa-hiking {
  color: #009688
}

.showcase .card {
  min-height: 425px;
}

#things-i-enjoy .card {
  min-height: 500px;
}

.loading .spinner-border {
  width: 4em; 
  height: 4em;
}

.book-list {
  width: 100%;
}

.list-group.card {
  border: none;
}

.paralax {
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
  height: 100px;
  background: #EEEEEE;
}


